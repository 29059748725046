<template>
<div>
    <v-toolbar flat>
        <v-btn icon @click="$emit('back')">
            <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
            Create Relationship
        </v-toolbar-title>
    </v-toolbar>
    <v-stepper v-model="wizardStep" class="elevation-0">
        <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="wizardStep > 1" :editable="wizardStep > 1" step="1">
                Organisation Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="wizardStep > 2" editable step="2">
                Organisation Users
                <!-- <small v-if="item.relatedOrganisation.name">These are users that are part of {{item.relatedOrganisation.name}}</small> -->
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="wizardStep > 3" editable step="3">
                Relationship Settings
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step :complete="wizardStep > 4" editable step="4">
                Assign Teams/Users
                <!-- <small>These are the people designated to manage this customer account</small> -->
            </v-stepper-step>

        </v-stepper-header>

        <v-stepper-content step="1">
            <v-card flat>
                <v-card-text>
                    <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="$emit('save', item)">
                        <el-form-item prop="name">
                            <v-row justify="center">
                                <v-col cols="12" sm="12" md="6">
                                    <el-input v-model="item.relatedOrganisation.name" placeholder="Name" clearable>
                                        <v-icon v-if="item.relatedOrganisation.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                                    </el-input>
                                </v-col>
                                <!-- <v-col cols="12" sm="12" md="6">
                                        <el-checkbox v-model="item.relatedOrganisation.isGroup" style="color: var(--v-primaryText-base)" label="This is a group of Companies"></el-checkbox>
                                    </v-col> -->
                            </v-row>
                        </el-form-item>
                        <el-form-item prop="address">
                            <el-input v-model="item.relatedOrganisation.address" placeholder="Address" type="textarea" rows="3" clearable>
                                <v-icon v-if="item.relatedOrganisation.address" slot="suffix" class="mr-1" small color="green">check</v-icon>
                                <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="registrationNumber">
                            <v-row justify="center">
                                <v-col cols="12" sm="12" md="6">
                                    <el-input v-model="item.relatedOrganisation.registrationNumber" placeholder="Registration Number" clearable>
                                    </el-input>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <el-input v-model="item.relatedOrganisation.vatNumber" placeholder="Tax/VAT No." clearable>
                                    </el-input>
                                </v-col>
                            </v-row>
                        </el-form-item>
                        <el-form-item style="text-align: center">
                            <el-button :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.relatedOrganisation.name" @click="wizardStep ++" block> {{!loading?'Next':''}}</el-button>
                        </el-form-item>
                    </el-form>
                </v-card-text>
            </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
            <v-row justify="end" class="my-1">
                <v-col cols="12" sm="12" md="8" lg="6">
                    <el-input style="background: rgba(255,255,255,0.1);backdrop-filter: blur(8px);" suffix-icon="el-icon-search" v-model="userSearch" clearable placeholder="Search" @keyup.enter.native="getRelations()">
                        <template slot="prepend">
                            <el-button @click="contactModal = true"><i class="el-icon-plus"></i></el-button>
                        </template>
                    </el-input>
                </v-col>
            </v-row>
            <v-data-table dense :items-per-page="5" :footer-props="{'items-per-page-options': [5]}" :items="item.relatedOrganisation.organisationUsers" :search="userSearch" :headers="userHeaders">
                <template v-slot:item.shared="{ item }">
                    <v-icon v-if="item.shared" color="green">check</v-icon>
                    <v-icon v-else color="red">cancel</v-icon>
                </template>
            </v-data-table>
            <v-row justify="center" class="my-2">
                <el-button :loading="loading" style="min-width: 40%" type="primary" @click="wizardStep ++" block> {{!loading?'Next':''}}</el-button>
            </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
            <v-card flat>
                <v-card-text>
                    <v-row justify="center" align="center">
                        <v-col cols="12" sm="12" md="6">
                            <h3>Accounting Relationship</h3>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-checkbox label="Customer" v-model="item.isCustomer" @change="updateOrganisation"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-checkbox label="Supplier" v-model="item.isSupplier" @change="updateOrganisation"></v-checkbox>

                        </v-col>
                        <v-col cols="12" class="text-center py-0 my-0">
                            <h4>What is {{item.relatedOrganisation.name}} to {{$store.state.currentOrg.name}}?
                            </h4>
                            <v-chip-group column multiple class="text-center">
                                <v-chip small outlined @click="item.producer = !item.producer, updateOrganisation" :color="item.producer?'green':'grey'">
                                    <v-icon small v-if="item.producer" color="green" class="mr-1">check</v-icon> Producer
                                </v-chip>
                                <v-chip small outlined @click="item.coldstore = !item.coldstore" :color="item.coldstore?'green':'grey'">
                                    <v-icon small v-if="item.coldstore" color="green" class="mr-1">check</v-icon> Coldstore
                                </v-chip>
                                <v-chip small outlined @click="item.consignee = !item.consignee" :color="item.consignee?'green':'grey'">
                                    <v-icon small v-if="item.consignee" color="green" class="mr-1">check</v-icon> Consignee
                                </v-chip>
                                <v-chip small outlined @click="item.shipper = !item.shipper" :color="item.shipper?'green':'grey'">
                                    <v-icon small v-if="item.shipper" color="green" class="mr-1">check</v-icon> Shipper
                                </v-chip>
                                <v-chip small outlined @click="item.forwarder = !item.forwarder" :color="item.forwarder?'green':'grey'">
                                    <v-icon small v-if="item.forwarder" color="green" class="mr-1">check</v-icon> Forwarder
                                </v-chip>
                                <v-chip small outlined @click="item.notifyParty = !item.notifyParty" :color="item.notifyParty?'green':'grey'">
                                    <v-icon small v-if="item.notifyParty" color="green" class="mr-1">check</v-icon> Notify Party
                                </v-chip>
                                <v-chip small outlined @click="item.buyer = !item.buyer" :color="item.buyer?'green':'grey'">
                                    <v-icon small v-if="item.buyer" color="green" class="mr-1">check</v-icon> Buyer
                                </v-chip>
                            </v-chip-group>
                        </v-col>

                        <v-col cols="12" class="text-center pt-0 mt-0">
                            <h4>What function does {{$store.state.currentOrg.name}} fulfil for {{item.relatedOrganisation.name}}?
                            </h4>
                            <v-chip-group column multiple class="text-center">
                                <v-chip small outlined @click="item.relatedProducer = !item.relatedProducer" :color="item.relatedProducer?'green':'grey'">
                                    <v-icon small v-if="item.relatedProducer" color="green" class="mr-1">check</v-icon> Producer
                                </v-chip>
                                <v-chip small outlined @click="item.relatedColdstore = !item.relatedColdstore" :color="item.relatedColdstore?'green':'grey'">
                                    <v-icon small v-if="item.relatedColdstore" color="green" class="mr-1">check</v-icon> Coldstore
                                </v-chip>
                                <v-chip small outlined @click="item.relatedConsignee = !item.relatedConsignee" :color="item.relatedConsignee?'green':'grey'">
                                    <v-icon small v-if="item.relatedConsignee" color="green" class="mr-1">check</v-icon> Consignee
                                </v-chip>
                                <v-chip small outlined @click="item.relatedShipper = !item.relatedShipper" :color="item.relatedShipper?'green':'grey'">
                                    <v-icon small v-if="item.relatedShipper" color="green" class="mr-1">check</v-icon> Shipper
                                </v-chip>
                                <v-chip small outlined @click="item.relatedForwarder = !item.relatedForwarder" :color="item.relatedForwarder?'green':'grey'">
                                    <v-icon small v-if="item.relatedForwarder" color="green" class="mr-1">check</v-icon> Forwarder
                                </v-chip>
                                <v-chip small outlined @click="item.relatedNotifyParty = !item.relatedNotifyParty" :color="item.relatedNotifyParty?'green':'grey'">
                                    <v-icon small v-if="item.relatedNotifyParty" color="green" class="mr-1">check</v-icon> Notify Party
                                </v-chip>
                                <v-chip small outlined @click="item.relatedBuyer = !item.relatedBuyer" :color="item.relatedBuyer?'green':'grey'">
                                    <v-icon small v-if="item.relatedBuyer" color="green" class="mr-1">check</v-icon> Buyer
                                </v-chip>
                            </v-chip-group>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <el-button :loading="loading" style="min-width: 40%" :disabled="(!item.isCustomer&&!item.isSupplier)||!checkOrganisationRules()||!checkRelatedRules()" type="primary" @click="wizardStep ++" block> {{!loading?'Next':''}}</el-button>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
            <v-card flat>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" class="text-center pb-0 mb-0">
                            <v-data-table show-select v-model="item.relationshipTeams" :single-select="false" :items="teams" :headers="teamHeaders"></v-data-table>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <el-button :loading="loading" style="min-width: 40%" type="primary" block @click="$emit('save', item)"> {{!loading?'Submit':''}}</el-button>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-stepper-content>

    </v-stepper>

    <el-dialog :visible.sync="contactModal" :fullscreen="$vuetify.breakpoint.mobile" width="750px" :title="'Add User'">
        <el-form ::model="contact" class="demo-form-inline">
            <el-form-item>
                <el-input v-model="contact.firstname" placeholder="Name"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="contact.surname" placeholder="Surname"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="contact.position" placeholder="Company Position"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="contact.emailAddress" required placeholder="Email*" @keyup.enter.native="getRelations()">
                    <v-icon v-if="validateEmail(contact.emailAddress)" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="contact.shared" label="Shared Contact"></el-checkbox>
            </el-form-item>
            <el-form-item class="text-center">
                <el-button type="plain" color="primary" :disabled="!validateEmail(contact.emailAddress)" @click="addUser()">
                    <v-icon>person_add</v-icon> Create Contact
                </el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
export default {
    props: ['item', 'teams'],
    data: () => ({
        contact: {},
        contactModal: false,
        loading: false,
        networkFunctions: [{
                name: 'Producer',
                attribute: 'producer',
                selected: true
            },
            {
                name: 'Coldstore',
                attribute: 'coldstore',
                selected: true
            },
            {
                name: 'Consignee',
                attribute: 'consignee',
                selected: true
            },
            {
                name: 'Shipper',
                attribute: 'shipper',
                selected: true
            },
            {
                name: 'Forwarder',
                attribute: 'forwarder',
                selected: true
            },
            {
                name: 'Buyer',
                attribute: 'buyer',
                selected: true
            },
            {
                name: 'Notify Party',
                attribute: 'notifyParty',
                selected: true
            }
        ],
        userHeaders: [{
                text: 'Name',
                value: 'user.firstname',
                align: 'center',
            },
            {
                text: 'Surname',
                value: 'user.surname',
                align: 'center',
            },
            {
                text: 'Email',
                value: 'user.emailAddress',
                align: 'center',
            },
            {
                text: 'Position',
                value: 'position',
                align: 'center',
            },
            {
                text: 'Shared',
                value: 'shared',
                align: 'center',
            },
        ],
        teamHeaders: [{
            text: 'Name',
            value: 'name',
            align: 'center',
        }],
        userSearch: '',
        inviteModal: false,
        wizardStep: 1,
    }),
    methods: {
        saveOrganisation() {},
        async updateOrganisation() {
            await this.$API.updateRelation(this.item)
        },
        validateEmail(email) {
            let result = false
            if (email) {
                result = email.match(
                    //eslint-disable-next-line
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            }
            return result
        }
    }
}
</script>
